import { Contact, ContactStatus, ContactType, StudentType } from '@wonderschool/common-base-types';
import { Checkbox, Dialog, InfoIcon, Label, Tooltip } from '@wonderschool/common-base-ui';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
// eslint-disable-next-line no-restricted-imports
import { Form, InputOnChangeData, Menu } from 'semantic-ui-react';
import Validator from 'validator';
import InlineError from '../../../Components/Messages/InlineError';
import ShowErrors from '../../../Components/Messages/ShowError';
import { Dropdown } from '../../../Components/Shared/Dropdown';
import { FamilyLoginPinGenerator } from '../../../Components/Shared/LoginPinGenerator';
import { showErrorToast, showSuccessToast } from '../../../Components/Shared/showToast';
import { fetchEmailUsage } from '../../../api/firebase/users';
import { createContactForStudent, updateContactForStudent } from '../../../contacts/contactsAPI';
import { useContacts } from '../../../contacts/contactsHooks';
import { INVITATION_TYPES } from '../../../contacts/enums';
import { formatFullName, phoneNumberFormat, phoneNumberParse } from '../../../helpers/utils';
import { useOrganization } from '../../../hooks/useOrganizations';
import { RELATIONSHIP_TYPES, RELATIONSHIP_TYPE_OPTIONS, RESPONSIBLE_FOR_BILLING_TYPE } from '../../consts';
import { addContactToStudent, saveStudent } from '../../studentsAPI';
import { useStudentOrPrimaryLocation } from '../../studentsHooks';
import { getOrCreateRelationshipNotes, transformRelationship } from '../../studentsUtils';
import InvoiceResponsibleConfirmationDialog from '../InvoiceResponsibleConfirmationDialog';
import ExistingContactConfirmationDialog from './ExistingContactConfirmationDialog';
import ExistingStaffDialog from './ExistingStaffDialog';
import { ContactFormErrors, FamilyModalFormData } from './types';

const PHONE_NUMBER_MASK = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const initialFormData: FamilyModalFormData = {
  id: '',
  uid: null,
  type: 'family',
  status: ContactStatus.new,
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  email: '',
  relationship: '',
  relationshipNotes: '',
  loginPin: '',
  allowLoginWithPin: true,
  enabled: true,
  isResponsibleForBilling: false,
  sendInvitation: true,
  invitationType: INVITATION_TYPES.parent,
  invitationId: '',
  allowParentLogin: '',
  allowStaffLogin: '',
  displayName: '',
};

interface FamilyEditContactModalProps {
  student: StudentType;
  contact?: Contact | null;
  isOpen: boolean;
  onClose: () => void;
  onReplaceExistingContact?: (contact: Contact) => void;
}

export default function FamilyEditContactModal({
  student,
  contact: initialContact,
  isOpen,
  onClose,
  onReplaceExistingContact,
}: FamilyEditContactModalProps) {
  const { t } = useTranslation();

  const isAdd = !initialContact || Object.keys(initialContact).length === 0;

  const title = isAdd ? t('familyModal.add') : t('familyModal.edit');

  return (
    <Dialog
      isOpen={isOpen}
      hasCloseButton
      onClose={onClose}
      data-testid="edit-parent-modal"
      extraClasses="md:w-1/2 md:max-w-1/2"
    >
      <Dialog.Title>
        <h1 className="border-b-2 border-b-gray-200 px-6 py-5 text-xl" data-testid={`${title}-title`}>
          {title}
        </h1>
      </Dialog.Title>
      <Dialog.Panel>
        <EditContactForm
          student={student}
          contact={initialContact}
          isAdd={isAdd}
          onClose={onClose}
          onReplaceExistingContact={onReplaceExistingContact}
        />
      </Dialog.Panel>
    </Dialog>
  );
}

function EditContactForm({ student, contact: initialContact, isAdd, onClose, onReplaceExistingContact }) {
  const { t } = useTranslation();
  const organization = useOrganization();
  const location = useStudentOrPrimaryLocation(student);
  const { contacts, familyContacts } = useContacts();

  const [isSaving, setIsSaving] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  const [errors, setErrors] = useState<ContactFormErrors>({});
  const [shouldEditContact, setShouldEditContact] = useState(true);
  const [existingContact, setExistingContact] = useState(null);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [formData, setFormData] = useState({ ...initialFormData });

  const [isOpenInvoiceResponsibleConfirmation, setIsOpenInvoiceResponsibleConfirmation] = useState(false);
  const [isOpenExistingContactConfirmationDialog, setIsOpenExistingContactConfirmationDialog] = useState(false);
  const [isOpenExistingStaffDialog, setIsOpenExistingStaffDialog] = useState(false);

  const parentCount = useMemo(() => {
    const { family } = student;
    return family
      ? Object.keys(family).filter((key) => family[key].relationship === RELATIONSHIP_TYPES.parent).length
      : 0;
  }, [student]);

  const contactsNotInStudent = useMemo(() => {
    const { familyMembers = [] } = student;

    return familyContacts.filter(
      (contact) => contact.status !== ContactStatus.disabled && !familyMembers.includes(contact.id)
    );
  }, [familyContacts, student]);

  const selectContactOptions = useMemo(() => {
    const options = contactsNotInStudent.map((contact) => ({
      key: contact.id,
      text: contact.displayName,
      value: contact.id,
    }));
    return options;
  }, [contactsNotInStudent]);

  const isEmailRequired = useMemo(() => {
    const isBaseUseCase = formData.sendInvitation;
    const isResponsibleForBilling = formData.isResponsibleForBilling;
    const isInvited = formData.status === ContactStatus.invited;
    const isVerified = formData.status === ContactStatus.verified;
    return isBaseUseCase || isResponsibleForBilling || isInvited || isVerified;
  }, [formData]);

  if (!isInitialized) initializeFormData();

  return (
    <>
      {isAdd && (
        <Menu pointing secondary>
          <Menu.Item
            name="editContact"
            active={shouldEditContact}
            onClick={() => {
              setShouldEditContact(true);
              setErrors({});
            }}
            data-testid="new-contact"
          >
            {t('familyModal.newContact')}
          </Menu.Item>

          <Menu.Item
            name="selectContact"
            active={!shouldEditContact}
            onClick={() => {
              setShouldEditContact(false);
              setErrors({});
            }}
            data-testid="existing-contact"
          >
            {t('familyModal.existing')}
          </Menu.Item>
        </Menu>
      )}
      <ShowErrors errors={errors} />

      <Form loading={isSaving} noValidate>
        {!shouldEditContact && renderSelectContactForm()}
        {shouldEditContact && renderEditContactForm()}
        <Form.Group>
          <Form.Button
            primary
            content={isAdd ? t('common.addContact') : t('familyModal.saveContact')}
            onClick={onSave}
            loading={isSaving}
            disabled={isSaving}
            data-testid={`${isAdd ? 'add-contact-btn' : 'save-contact-btn'}`}
          />
          <Form.Button content={t('common.cancel')} onClick={onCloseLocal} data-testid="cancel-btn" />
        </Form.Group>
      </Form>

      <ExistingStaffDialog
        existingContact={existingContact}
        isOpen={isOpenExistingStaffDialog}
        onClose={() => {
          setExistingContact(null);
          setIsOpenExistingStaffDialog(false);
        }}
      />
      <ExistingContactConfirmationDialog
        existingContact={existingContact}
        isOpen={isOpenExistingContactConfirmationDialog}
        onYes={onSaveExistingContact}
        onClose={() => {
          setExistingContact(null);
          setIsOpenExistingContactConfirmationDialog(false);
        }}
      />
      <InvoiceResponsibleConfirmationDialog
        isResponsibleForBilling={formData.isResponsibleForBilling}
        parentCount={parentCount}
        isOpen={isOpenInvoiceResponsibleConfirmation}
        onClose={() => {
          setIsOpenInvoiceResponsibleConfirmation(false);
        }}
        onYes={() => {
          setFormData({ ...formData, isResponsibleForBilling: true });
          setIsOpenInvoiceResponsibleConfirmation(false);
        }}
      />
    </>
  );

  function renderEditContactForm() {
    return (
      <>
        <Form.Field>
          <h3 className="text-xl" data-testid="personal-info-title">
            {t('familyModal.personalInformation')}
          </h3>
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field error={!!errors?.relationship}>
            <Form.Field
              translator={t}
              control={Dropdown}
              label={t('common.relationship')}
              fluid
              search
              selection
              clearable
              required
              id="relationship"
              name="relationship"
              placeholder={t('common.relationship')}
              options={RELATIONSHIP_TYPE_OPTIONS}
              value={formData.relationship}
              onChange={onChange}
              data-testid="relationship"
            />
            {errors.relationship && <InlineError text={errors.relationship} data-testid="relationship-error" />}
          </Form.Field>
          <Form.Field>
            <Form.Input
              disabled={formData.relationship === 'parent'}
              type="text"
              id="relationshipNotes"
              name="relationshipNotes"
              value={formData.relationshipNotes ?? ''}
              onChange={onChange}
              label={t('familyModal.typeOfRelationship')}
              placeholder={t('familyModal.tellAboutRelationship')}
              data-testid="relationship-notes"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.firstName}>
            <Form.Input
              required
              type="text"
              id="firstName"
              key="contact-firstName"
              name="firstName"
              value={formData.firstName}
              onChange={onChange}
              label={t('common.firstName')}
              placeholder={t('common.firstName')}
              data-testid="first-name"
            />
            {errors.firstName && <InlineError text={errors.firstName} data-testid="first-name-error" />}
          </Form.Field>
          <Form.Field>
            <Form.Input
              type="text"
              id="middleName"
              name="middleName"
              value={formData.middleName}
              onChange={onChange}
              label={t('common.middleName')}
              placeholder={t('common.middleName')}
              data-testid="middle-name"
            />
          </Form.Field>
        </Form.Group>
        <Form.Field error={!!errors.lastName}>
          <Form.Input
            required
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={onChange}
            label={t('common.lastName')}
            placeholder={t('common.lastName')}
            data-testid="last-name"
          />
          {errors.lastName && <InlineError text={errors.lastName} data-testid="last-name-error" />}
        </Form.Field>

        <h3 className="mb-4 mt-7 border-b-2 border-b-gray-200 pb-1 text-xl" data-testid="contact-info-title">
          {t('familyModal.contactInformation')}
        </h3>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.email}>
            <Form.Input
              required={isEmailRequired}
              type="text"
              id="email"
              name="email"
              value={formData.email && formData.email.indexOf('moxit_') !== -1 ? '' : formData.email}
              onChange={onChange}
              label={t('common.email')}
              placeholder={t('common.email')}
              data-testid="contact-email"
            />
            {errors.email && <InlineError text={errors.email} data-testid="contact-email-error" />}
          </Form.Field>

          <Form.Field error={!!errors.phone}>
            <Form.Input
              type="text"
              id="phone"
              name="phone"
              onChange={maskedOnChange}
              label={t('common.cellPhone')}
              control={MaskedInput}
              mask={PHONE_NUMBER_MASK}
              guide={false}
              value={formData.phone}
              placeholder={'(123) 456-7890'}
              data-testid="contact-phone"
            />
            {errors.phone && <InlineError text={errors.phone} data-testid="contact-phone-error" />}
          </Form.Field>
        </Form.Group>
        <h3 className="mb-4 mt-7 border-b-2 border-b-gray-200 pb-1 text-xl" data-testid="acc-info-title">
          {t('familyModal.accountInformation')}
        </h3>
        <Form.Group widths="equal">
          <Form.Field>
            <Label extraClasses="font-bold">
              {t('familyModal.approvedPickUp')}
              <Tooltip content={t('familyModal.pickUpTooltip')} action="hover">
                <InfoIcon className={'ml-1 h-5'} />
              </Tooltip>
            </Label>
            <Checkbox
              id="allowLoginWithPin"
              name="allowLoginWithPin"
              label={formData.allowLoginWithPin ? t('common.yes') : t('common.no')}
              onChange={onSwitchChange}
              checked={!!formData.allowLoginWithPin}
              data-testid="allow-login-with-pin"
              type="switch"
            />
          </Form.Field>
          {formData.allowLoginWithPin && formData.status === ContactStatus.verified && formData.uid && (
            <Form.Field error={!!errors.loginPin} data-testid="login-pin-error-field">
              <FamilyLoginPinGenerator
                uid={formData.uid}
                organizationId={organization.id}
                loginPin={formData.loginPin}
                onGenerating={() => setIsSaving(true)}
                onGenerated={(loginPin) => {
                  setIsSaving(false);
                  setFormData({ ...formData, loginPin });
                }}
                onError={() => {
                  setIsSaving(false);
                  setErrors({
                    ...errors,
                    loginPin: 'Failed to generate PIN Code.',
                  });
                }}
              />
              {errors.loginPin && <InlineError text={errors.loginPin} data-testid="login-pin-error" />}
            </Form.Field>
          )}
        </Form.Group>

        {formData.relationship === RELATIONSHIP_TYPES.parent && (
          <Form.Field>
            <label>
              {t('common.primaryPayer')}
              <Tooltip content={t('familyModal.primaryPayerToolTip')} action="hover">
                <InfoIcon className={'ml-1 h-5'} />
              </Tooltip>
            </label>
            <Checkbox
              id="responsible"
              name="responsible"
              label={formData.isResponsibleForBilling ? t('common.yes') : t('common.no')}
              onChange={onChangeResponsibleForBilling}
              checked={!!formData.isResponsibleForBilling}
              data-testid="responsible-checkbox"
              type="switch"
            />
          </Form.Field>
        )}
        {formData.status === ContactStatus.new && (
          <Form.Field>
            <label>{t('familyModal.inviteContact')}</label>
            <Checkbox
              id="sendInvitation"
              name="sendInvitation"
              label={formData.sendInvitation ? t('common.yes') : t('common.no')}
              onChange={onSwitchChange}
              checked={!!formData.sendInvitation}
              data-testid="send-invitation-checkbox"
              disabled={!formData.allowLoginWithPin && formData.relationship === RELATIONSHIP_TYPES.contact}
              type="switch"
            />
          </Form.Field>
        )}
      </>
    );
  }

  function renderSelectContactForm() {
    return (
      <>
        <h3 className="text-xl" data-testid="personal-info">
          {t('familyModal.personalInformation')}
        </h3>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.selectedContact} data-testid="contact-name-error-field">
            <Form.Field
              control={Dropdown}
              label={t('familyContact.contactName')}
              fluid
              search
              selection
              clearable
              required
              id="users"
              name="users"
              placeholder={t('common.selectContact')}
              options={selectContactOptions}
              value={selectedContact?.id ?? ''}
              onChange={onChangeSelectedContact}
              data-testid="contact-name-field"
            />
            {errors.selectedContact && (
              <InlineError text={errors.selectedContact} data-testid="contact-name-field-error" />
            )}
          </Form.Field>
          <Form.Field>
            <label>{t('common.relationship')}</label>
            <div>{selectedContact?.relationship ? getOrCreateRelationshipNotes(selectedContact, t) : '--'}</div>
          </Form.Field>
        </Form.Group>

        <h3 className="mb-4 mt-7 border-b-2 border-b-gray-200 pb-1 text-xl" data-testid="contact-info-title">
          {t('familyModal.contactInformation')}
        </h3>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('common.email')}</label>
            <div>{selectedContact?.email || '--'}</div>
          </Form.Field>
          <Form.Field>
            <label>{t('common.phoneNumber')}</label>
            <div>{phoneNumberParse(selectedContact?.phone) || '--'}</div>
          </Form.Field>
        </Form.Group>
      </>
    );
  }

  function initializeFormData() {
    if (initialContact) {
      const { relationship, relationshipNotes, phone, ...rest } = transformRelationship(initialContact);

      let isResponsibleForBilling = false;

      if (relationship === RELATIONSHIP_TYPES.parent) {
        if (student.responsibleForBilling) {
          const responsibleForBillingId = student.responsibleForBilling.uid ?? student.responsibleForBilling.id;
          const initialContactId = initialContact.uid ?? initialContact.id;
          isResponsibleForBilling = responsibleForBillingId === initialContactId;
        }
      }

      setFormData({
        ...rest,
        relationship,
        relationshipNotes,
        phone: phoneNumberParse(phone),
        isResponsibleForBilling,
        sendInvitation: false,
      });
    } else {
      setFormData({ ...initialFormData });
    }
    setIsInitialized(true);
  }

  function onCloseLocal(e) {
    if (e) e.preventDefault();
    setFormData(initialFormData);
    setErrors({});
    setIsSaving(false);
    if (onClose) onClose();
  }

  async function onSaveExistingContact() {
    setErrors({});
    if (!existingContact) return;

    setShouldEditContact(false);
    setSelectedContact(existingContact);
    setExistingContact(null);
  }

  async function onSave(e) {
    if (e) e.preventDefault();
    if (isSaving) return; // prevent double click

    setIsSaving(true);
    try {
      const errors = validate();

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      const success = shouldEditContact ? await saveEditContact() : await saveSelectedContact();

      if (success && onClose) onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  }

  async function saveEditContact() {
    const { sendInvitation, isResponsibleForBilling, allowParentLogin, allowStaffLogin, ...rest } = formData;

    if (!student?.id || !organization?.id) {
      setIsSaving(false);
      return false;
    }

    rest.phone = phoneNumberFormat(rest.phone);
    rest.displayName = formatFullName(rest, true);

    const email = formData.email?.toLowerCase();
    const originalEmail = initialContact?.email?.toLowerCase();
    const isEditEmail = originalEmail !== email;

    // this is not an update of an existing user.
    if ((isAdd || isEditEmail) && !!email) {
      const matchingContact = contacts.find((_contact) => _contact?.email?.toLowerCase() === email);
      let emailUsage: { hasRootUser: boolean; hasAuthUser: boolean; uid: string } | null = null;
      if (matchingContact) {
        setExistingContact(matchingContact);
        if (matchingContact.type === ContactType.STAFF) setIsOpenExistingStaffDialog(true);
        else setIsOpenExistingContactConfirmationDialog(true);
        return false;
      } else if (email) {
        // invoke callable to check for root org or auth user
        emailUsage = await fetchEmailUsage(email, organization.id);
        if (emailUsage?.hasRootUser) {
          setErrors({
            email: t('common.userAlreadyExistsError'),
          });
          return false;
        }
      }
      // if auth exists and no root user
      // and if shouldNotSendNetworkMigrationMail is true, than update status to new and add uid. No email will be sent as it is 1.0 to 2.0 Migration
      // and if shouldNotSendNetworkMigrationMail is false, than update status to verified and add uid. Welcome email will be sent.
      if (emailUsage && emailUsage.hasAuthUser && !emailUsage.hasRootUser && emailUsage.uid) {
        rest.status = organization?.shouldNotSendNetworkMigrationMail ? ContactStatus.new : ContactStatus.verified;
        if (rest.status === ContactStatus.verified) rest.uid = emailUsage.uid;
      } else {
        // this ensures a new invitation can be generated
        rest.invitationId = null;
        // verified users need to be disconnected from the previous user account
        // in order to allow a new invite to be sent.
        if (rest.status === ContactStatus.verified) {
          rest.uid = null;
        }
        rest.status = sendInvitation ? ContactStatus.inviting : ContactStatus.new;
      }
    }
    if (sendInvitation && (rest.status === ContactStatus.new || rest.status === ContactStatus.invited)) {
      rest.status = ContactStatus.inviting;
    }
    try {
      const { contactDocRef, contact } = isAdd
        ? await createContactForStudent(organization, location, student, rest)
        : await updateContactForStudent(organization, location, student, rest);

      // Add the contact to the student.family map so the UI is responsive.
      // The Student firestore listener will overwrite this with the correct data. DO NOT PERIST THIS HERE.
      if (!student.family) student.family = {};
      if (!student.family[contactDocRef.id]) student.family[contactDocRef.id] = contact;

      if (rest.relationship && isResponsibleForBilling) {
        const responsibleForBillingType =
          rest.status === ContactStatus.verified
            ? RESPONSIBLE_FOR_BILLING_TYPE.parent
            : RESPONSIBLE_FOR_BILLING_TYPE.invitation;

        const data = {
          id: student.id,
          responsibleForBilling: {
            id: contactDocRef.id,
            displayName: rest.displayName,
            type: responsibleForBillingType,
            email: rest.email,
            modifiedDate: Date.now(),
            contactStatus: rest.status,
            uid: rest.uid ?? null,
          },
          uid: '',
          invitationId: '',
        };
        if (rest.uid) data.uid = rest.uid;
        if (rest.invitationId) data.invitationId = rest.invitationId;
        await saveStudent(organization.id, data);
      }

      showSaveSuccessToast(rest, isAdd, t);
      return true;
    } catch (error) {
      showSaveErrorToast(rest, t);
      console.log('Error updating parent', error);
      return false;
    }
  }

  async function saveSelectedContact() {
    if (!selectedContact) return false;

    // we tried to update a contact's email but it was already taken
    // we want to delete the original contact
    const isReplaceContactViaEmail = initialContact?.id && initialContact.id !== selectedContact.id;
    if (isReplaceContactViaEmail) {
      onReplaceExistingContact(initialContact);
    }

    await addContactToStudent(organization.id, student.id, selectedContact);
    showSaveSuccessToast(selectedContact, true, t);
    return true;
  }

  function validate() {
    const errors: ContactFormErrors = {};

    if (shouldEditContact) {
      if (!formData.relationship) errors.relationship = t('familyModal.relationshipIsRequired');
      if (!formData.firstName) errors.firstName = t('common.firstNameRequired');
      if (!formData.lastName) errors.lastName = t('common.lastNameRequired');

      if (formData.phone) {
        if (!Validator.isMobilePhone(formData.phone, 'en-US')) errors.phone = t('common.invalidPhone');
      }
      if (!!formData.email && !Validator.isEmail(formData.email)) {
        errors.email = t('common.invalidEmail');
      } else if (isEmailRequired) {
        if (!formData.email || formData.email.indexOf('moxit_') > -1) errors.email = t('common.emailRequired');
      }

      if (
        formData.relationship &&
        formData.relationship !== RELATIONSHIP_TYPES.contact &&
        formData.isResponsibleForBilling &&
        (!formData.email || !Validator.isEmail(formData.email))
      ) {
        errors.email = t('familyModal.emailRequiredForBilling');
      }
    } else {
      if (!selectedContact) errors.selectedContact = t('familyModal.selectContact');
    }
    return errors;
  }
  function removeError(name: string) {
    if (errors[name]) {
      const { [name]: _extracted, ...restErrors } = errors;
      setErrors(restErrors);
    }
  }
  function onChangeSelectedContact(e, { name, value }) {
    e.preventDefault();
    removeError(name);
    const contact = familyContacts.find((u) => u.id === value);
    setSelectedContact(contact ?? null);
  }

  function onChange(e: ChangeEvent<HTMLInputElement>, { name, value, checked }: InputOnChangeData) {
    removeError(name);
    let isResponsibleForBilling = formData.isResponsibleForBilling;

    if (name === 'relationship' && value === RELATIONSHIP_TYPES.parent && parentCount <= 0) {
      isResponsibleForBilling = true;
    }

    setFormData({
      ...formData,
      [name]: checked ?? value,
      isResponsibleForBilling,
    });
  }

  function onSwitchChange(e) {
    const { name, checked, value } = e.target;
    removeError(name);
    const isResponsibleForBilling = formData.isResponsibleForBilling;
    if (name === 'allowLoginWithPin' && !checked && formData.relationship === RELATIONSHIP_TYPES.contact) {
      formData.sendInvitation = false;
    }

    setFormData({
      ...formData,
      [name]: checked ?? value,
      isResponsibleForBilling,
    });
  }
  function maskedOnChange(e) {
    onChange(e, { name: e.target.name, value: e.target.value });
  }

  function onChangeResponsibleForBilling(e) {
    const { checked } = e.target;
    if (formData.relationship !== RELATIONSHIP_TYPES.parent) return;

    const isCurrentlyResponsibleForBilling =
      parentCount <= 0 ||
      (student.responsibleForBilling && student.responsibleForBilling.id === (formData.id || formData.uid));

    if (!checked && !isCurrentlyResponsibleForBilling) {
      removeError('email');
      setFormData({ ...formData, isResponsibleForBilling: false });
      return;
    }

    if (parentCount <= 0) {
      setFormData({ ...formData, isResponsibleForBilling: !!checked });
    } else {
      setIsOpenInvoiceResponsibleConfirmation(true);
    }
  }
}

function showSaveSuccessToast({ displayName }, isAdd, t) {
  const title = isAdd ? t('familyModal.familyMemberAdded') : t('familyModal.familyMemberUpdated');
  const message = isAdd
    ? t('familyModal.familyMemberAddedName', { displayName })
    : t('familyModal.updatedFamilyMemberName', { displayName });
  showSuccessToast(title, message);
}

function showSaveErrorToast({ displayName }, t) {
  const message = t('familyModal.updateFamilyErrorName', {
    displayName,
  });
  showErrorToast(t('familyModal.familyMemberUpdateFailed'), message);
}
